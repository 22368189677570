import React from 'react';
import { useRatingPageQuery } from 'store/graphql';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { PageError } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { PageSheet } from 'components/_layouts/PageSheet';
import { ShareButton } from 'components/ShareButton';
import { RatingList } from '../components/RatingList';
import { RatingHeader } from '../components/RatingHeader';
import s from './RatingPage.module.scss';

const PageName = 'Оперативный рейтинг';

export function RatingPage() {
  // TODO: убрать не-используемые поля
  const { loading, data } = useRatingPageQuery();

  if (loading) return <Loader />;
  if (!data) return <PageError />;

  return (
    <>
      <PageTitle backTo={'/'}>{PageName}</PageTitle>
      <PageSheet>
        <div className={s.RatingPage}>
          <RatingHeader data={data} />
          <RatingList data={data} />
          <SharePDF kind={SharePDFKind.Rating} data={data.dashboard.operationalRating}>
            {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
          </SharePDF>
        </div>
      </PageSheet>
    </>
  );
}
