import React from 'react';
import { Link } from 'react-router-dom';
import { DATE_SHORT_YEAR, formatDate, useQueryParams } from '@digital-gov/ui-utils';
import { DocumentType } from 'store/graphql';
import { DocumentUtils } from 'utils/DocumentUtils';
import { ArrowRight, Icon, IconSize } from 'components/Icon';
import { Label } from 'components/Label';
import { URL_KEY_DOCUMENTS_ARCHIVE } from '../urlKeys';
import s from './DocumentItem.module.scss';

export type DocumentItemType = Pick<
  DocumentType,
  'documentId' | 'documentType' | 'documentName' | 'status' | 'statusText' | 'statusDate'
>;

export interface DocumentItemProps {
  document: DocumentItemType;
}

export function DocumentItem({ document }: DocumentItemProps) {
  const [isArchive] = useQueryParams(URL_KEY_DOCUMENTS_ARCHIVE);

  let to = `/documents/${document.documentId}`;
  if (isArchive) to += '?source=archive';

  const [label, labelType] = DocumentUtils.getDocumentStatusLabel(document.status);
  const documentType = DocumentUtils.getDocumentType(document.documentType);

  return (
    <Link className={s.DocumentItem} to={to}>
      <div className={s.DocumentItem__header}>
        <div className={s.DocumentItem__docType}>{documentType}</div>

        <div className={s.DocumentItem__date}>{formatDate(document.statusDate, DATE_SHORT_YEAR)}</div>
        <Icon icon={ArrowRight} size={IconSize.Small} className={s.DocumentItem__arrowRight} />
      </div>

      <div className={s.DocumentItem__description}>{document.documentName}</div>

      <Label type={labelType}>{document.statusText ?? label}</Label>
    </Link>
  );
}
