import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthApolloProvider } from '@digital-gov/auth-apollo-store';
// import { InstallPromptProvider } from 'store/installPrompt';
// import { updatesAvailableEvent, UpdatesProvider } from 'store/updates';
import { AuthorityProvider } from 'store/authority';
import { basename } from 'store/basename';
import { rnMessenger } from 'store/rnMessenger';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// @ts-ignore: rnMessenger
window.rnMessenger = rnMessenger;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <HelmetProvider>
        <AuthApolloProvider>
          {/* <InstallPromptProvider> */}
          {/* <UpdatesProvider> */}
          <AuthorityProvider>
            <App />
          </AuthorityProvider>
          {/* </UpdatesProvider> */}
          {/* </InstallPromptProvider> */}
        </AuthApolloProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   // a custom event (RxJS coming soon)
//   onUpdate: () => window.dispatchEvent(updatesAvailableEvent)
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
