import clsx from 'clsx';
import React from 'react';
import { Icon, IconSize, Info } from 'components/Icon';
import s from './InfoIcon.module.scss';

export interface InfoIconProps {
  active: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export function InfoIcon({ active, onClick }: InfoIconProps) {
  return (
    <Icon
      className={clsx(s.InfoIcon, active && s.InfoIcon_active)}
      icon={Info}
      size={IconSize.Small}
      onClick={onClick}
    />
  );
}
