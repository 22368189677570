import { URL_KEY_DOCUMENTS_TYPE } from 'routes/documents/urlKeys';
import { useQueryParams } from '@digital-gov/ui-utils';
import { DocumentTypeEnum } from 'store/graphql';

export const useQueryTypes = () =>
  useQueryParams(URL_KEY_DOCUMENTS_TYPE, null, {
    getter: (value): DocumentTypeEnum | null => {
      return value && Object.values(DocumentTypeEnum).includes(value as DocumentTypeEnum)
        ? (value as DocumentTypeEnum)
        : null;
    },
    setter: (value) => value ?? ''
  });
