import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { useRequestPageQuery, useViewRequestMutation } from 'store/graphql';
import { RequestUtils } from 'utils/RequestUtils';
import { Loader } from 'components/Loader';
import { PageError } from 'components/ErrorMessage';
import { PageTitle } from 'components/PageTitle';
import { ToggleTabs } from 'components/ToggleTabs';
import { Label } from 'components/Label';
import { ShareButton } from 'components/ShareButton';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import s from './RequestPage.module.scss';

export function RequestPage() {
  const { requestId } = useParams<{ requestId: string }>();
  const { loading, error, data } = useRequestPageQuery();

  const [viewRequest] = useViewRequestMutation({
    ignoreResults: true,
    variables: { requestId: +(requestId as string) }
  });

  useEffect(() => {
    // отмечает запрос как прочитанный
    viewRequest().catch((err) => console.error(err));
    // eslint-disable-next-line
  }, []);

  if (loading) return <Loader />;
  if (!data || !!error) return <PageError />;

  const request = data.dashboard.notifications.requests.find((request) => request.requestId === +(requestId as string));
  if (!request) return <Navigate to={'/'} replace />;

  const [label, labelType] = RequestUtils.getRequestStatusLabel(request.statusCode, request.statusDisplayContent);

  return (
    <React.Fragment>
      <PageTitle backTo={'/'}>{request.requestTypeName}</PageTitle>
      <div className={s.RequestPage}>
        <ToggleTabs
          options={[
            { label: 'Запрос', value: 'Request' },
            { label: 'История', value: 'History', disabled: true }
          ]}
        />

        <div className={s.RequestPage__card}>
          <div className={s.RequestPage__header}>
            <Label type={labelType}>{label}</Label>
            <div className={s.RequestPage__date}>
              {formatDate(request.stateUpdatedAt || request.createdDate, DATE_SHORT_YEAR)}
            </div>
          </div>

          <p className={s.RequestPage__name}>{request.requestName}</p>

          {!!request.groundsNames.length && (
            <article className={s.RequestPage__content}>
              <div className={s.RequestPage__label}>Основание запроса</div>
              {request.groundsNames.map((nameLine) => (
                <p key={nameLine} className={s.RequestPage__value}>
                  {nameLine}
                </p>
              ))}
            </article>
          )}

          <article className={s.RequestPage__content}>
            <div className={s.RequestPage__label}>Номер</div>
            <p className={s.RequestPage__value}>{request.requestNumber}</p>
          </article>

          <article className={s.RequestPage__content}>
            <div className={s.RequestPage__label}>Дата</div>
            <p className={s.RequestPage__value}>{formatDate(request.createdDate, DATE_SHORT_YEAR)}</p>
          </article>

          <SharePDF kind={SharePDFKind.Request} data={request}>
            {({ share, isLoading }) => (
              <ShareButton className={s.RequestPage__share} share={share} isLoading={isLoading} />
            )}
          </SharePDF>
        </div>
      </div>
    </React.Fragment>
  );
}
