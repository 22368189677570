import { gql } from '@apollo/client';
export const GET_TOKEN = gql(`
  query GetToken($input: AuthGetTokensInput!) {
    getTokens(input: $input) {
      accessToken
      expiresIn
      refreshToken
    }
  }
`);
export const REFRESH_TOKEN = gql(`
  query RefreshToken($input: AuthRefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      expiresIn
      refreshToken
    }
  }
`);
