import React from 'react';
import { formatNumber } from '@digital-gov/ui-utils';
import { KeySystemType } from 'store/graphql';
import { RatioBar, RatioBarSize } from 'components/RatioBar';
import { KeyValuePair } from 'components/KeyValuePair';
import s from './SystemCard.module.scss';

export type SystemCardProps = Pick<KeySystemType, 'name' | 'budgetTotal' | 'budgetDevelopment' | 'budgetExploitation'>;

export function SystemCard({ name, budgetTotal, budgetDevelopment, budgetExploitation }: SystemCardProps) {
  return (
    <div className={s.SystemCard}>
      <div className={s.SystemCard__header}>
        <h2 className={s.SystemCard__heading}>{name}</h2>
        <div className={s.SystemCard__budget}>{budgetTotal}</div>
      </div>

      <RatioBar
        size={RatioBarSize.Small}
        data={[
          { value: budgetDevelopment, color: '#7cb6fa' },
          { value: budgetExploitation, color: '#0b3fb2' }
        ]}
      />

      <div className={s.SystemCard__pairs}>
        <KeyValuePair pointColor={'#7cb6fa'} keyLabel={'Создание и развитие'}>
          {formatNumber(budgetDevelopment, 2, true)}
        </KeyValuePair>
        <KeyValuePair pointColor={'#0b3fb2'} keyLabel={'Эксплуатация'}>
          {formatNumber(budgetExploitation, 2, true)}
        </KeyValuePair>
      </div>
    </div>
  );
}
