import clsx from 'clsx';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import s from './NavBarItem.module.scss';

type NavBarIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export type NavBarItemProps = {
  Icon: NavBarIcon;
  to?: string;
  pattern?: string;
  disabled?: boolean;
  children: string;
};

export function NavBarItem({ Icon, to = '/', pattern, disabled, children }: NavBarItemProps) {
  const active = useMatch(pattern || to) !== null;
  const className = clsx(s.NavBarItem, active && !disabled && s.NavBarItem_active, disabled && s.NavBarItem_disabled);

  if (!disabled) {
    return (
      <Link to={to} className={className}>
        <Icon />
        <span>{children}</span>
      </Link>
    );
  } else {
    return (
      <div className={className}>
        <Icon />
        <span>{children}</span>
      </div>
    );
  }
}
