import clsx from 'clsx';
import { ElementType } from 'react';
import { WrapperComponentProps } from '@digital-gov/ui-utils';
import s from './Label.module.scss';

export enum LabelType {
  Neutral = 'neutral',
  Success = 'success',
  Warning = 'warning',
  Error = 'error'
}

export interface LabelProps {
  className?: string;
  type?: LabelType;
  children: string;
}

export const Label = <ComponentType extends ElementType = 'div'>({
  className,
  Component = 'div',
  type = 'neutral',
  children,
  ...componentProps
}: WrapperComponentProps<ComponentType, LabelProps>) => {
  return (
    <Component className={clsx(s.Label, s[`Label_${type}`], className)} {...componentProps}>
      <span className={s.Label__text}>{children}</span>
    </Component>
  );
};
