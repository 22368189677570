import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { KeyIndicatorHistoryType } from 'store/graphql';
import { Position } from 'features/_indicators';
import s from './HistoryItem.module.scss';

export interface HistoryItemProps extends KeyIndicatorHistoryType {}

export const HistoryItem = ({ changesDate, executor, approvedFromOgv }: HistoryItemProps) => {
  return (
    <div className={s.HistoryItem}>
      <div className={s.HistoryItem__date}>{formatDate(changesDate, DATE_SHORT_YEAR)}</div>
      <div className={s.HistoryItem__executor}>{executor}</div>
      <div className={s.HistoryItem__position}>
        <Position approved={approvedFromOgv}>Позиция ведомства</Position>
      </div>
    </div>
  );
};
