import React, { useEffect, useRef } from 'react';
import { formatDate } from '@digital-gov/ui-utils';
import { PageSheet } from 'components/_layouts/PageSheet';
import { DocumentTypeEnum } from 'store/graphql';
import { Tabs } from 'components/Tabs';
import { Divider } from 'components/Divider';
import { Placeholder, PlaceholderVariant } from 'components/Placeholder';
import { Loader, LoaderSize } from 'components/Loader';
import { DocumentFilter } from '../types';
import { DocumentItem, DocumentItemType } from './DocumentItem';
import s from './DocumentList.module.scss';

export interface DocumentListProps {
  documents?: DocumentItemType[];
  documentTypes: DocumentTypeEnum | null;
  onDocumentTypesChange: (value: DocumentTypeEnum | null) => void;
  loading?: boolean;
  fetchingMore?: boolean;
  onFetchMore?: () => void;
}

const options: { id: DocumentFilter; label: string; disabled?: boolean }[] = [
  { id: 'All', label: 'Все' },
  { id: DocumentTypeEnum.TechnicalTask, label: 'ТЗ' },
  { id: DocumentTypeEnum.Concept, label: 'Концепция' },
  { id: DocumentTypeEnum.RegulatoryAct, label: 'НПА' },
  { id: DocumentTypeEnum.Addition, label: 'Допы' },
  { id: DocumentTypeEnum.Roadmap, label: 'Дорожная карта' }
];

export function DocumentList({
  documents,
  documentTypes,
  onDocumentTypesChange,
  loading,
  fetchingMore,
  onFetchMore
}: DocumentListProps) {
  const endOfListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!endOfListRef.current || !onFetchMore) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && onFetchMore?.();
    });

    observer.observe(endOfListRef.current);
    return () => observer.disconnect();
  }, [onFetchMore]);

  const hasData = documents && documents.length > 0;

  return (
    <PageSheet className={s.DocumentList}>
      <Tabs<DocumentFilter>
        innerScroll
        value={documentTypes ? (options.find(({ id }) => id === documentTypes)?.id ?? 'All') : 'All'}
        onChange={(val) => onDocumentTypesChange(val === 'All' ? null : val)}
        options={options}
      />

      <div className={s.DocumentList__list}>
        {hasData ? (
          documents.map((document, index, documents) => (
            <React.Fragment key={document.documentId}>
              <DocumentItem document={document} />
              {index !== documents.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : loading && !documents ? (
          <Loader />
        ) : (
          <Placeholder variant={PlaceholderVariant.Documents}>
            На {formatDate(new Date(), 'dd.MM.yyyy')} нет документов <br />
            на экспертизе в Минцифры России
          </Placeholder>
        )}
      </div>

      {fetchingMore && <Loader className={s.DocumentList__loader} size={LoaderSize.Medium} />}

      {onFetchMore && <div ref={endOfListRef} />}
    </PageSheet>
  );
}
