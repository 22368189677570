import { createContext } from 'react';

export const updatesContextDefaultValue = {
  checkForUpdates: () => Promise.resolve(),
  updatesAvailable: false
};

export type IUpdatesContextValue = typeof updatesContextDefaultValue;

export const UpdatesContext = createContext<IUpdatesContextValue>(updatesContextDefaultValue);
