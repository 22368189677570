import React, { useState } from 'react';
import { BottomSheet } from '../../../components/BottomSheet';
import { useWidgetSettings } from '../context/useWidgetSettings';
import { Button, ButtonVariant } from '../../../components/Button';
import { useWidgets_UpdateMutation } from '../../../store/graphql';
import { Loader, LoaderSize } from '../../../components/Loader';
import { SettingsDNDList } from './SettingsDNDList';
import s from './WidgetSettingsSheet.module.scss';

export function WidgetSettingsSheet() {
  const { visible, setVisible, serialize, settingsList, loading } = useWidgetSettings();
  const [inProgress, setInProgress] = useState<boolean>(false);

  const [updateWidgetSettings] = useWidgets_UpdateMutation();

  if (!visible || !settingsList.length) return null;
  if (loading) return <Loader />;

  const onClose = () => {
    setVisible(false);
  };

  const onSave = () => {
    if (inProgress) return;
    setInProgress(true);
    updateWidgetSettings({ variables: { input: serialize() } })
      .then(() => onClose())
      .finally(() => setInProgress(false));
  };

  return (
    <BottomSheet onClose={onClose} title="Настройки виджетов">
      <span className={s.WidgetSettingsSheet__text}>
        Вы можете изменить порядок отображения и отключить ненужные виджеты
      </span>
      <SettingsDNDList />
      <Button className={s.WidgetSettingsSheet__button} onClick={onSave}>
        <div className={s.WidgetSettingsSheet__buttonRow}>
          {inProgress ? <Loader size={LoaderSize.xSmall} /> : 'Сохранить'}
        </div>
      </Button>
      <Button className={s.WidgetSettingsSheet__button} variant={ButtonVariant.Secondary} onClick={onClose}>
        Отмена
      </Button>
    </BottomSheet>
  );
}
