import React from 'react';
import { KeyIndicatorsType } from 'store/graphql';
import { KeyValuePair } from 'components/KeyValuePair';
import { RatioBar } from 'components/RatioBar';
import { Divider } from 'components/Divider';
import { ShareButton } from 'components/ShareButton';
import { Card } from 'features/_indicators';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import s from './StatsCard.module.scss';

export interface StatsCardProps {
  keyIndicators: Pick<KeyIndicatorsType, 'key' | 'total' | 'achievedOrApproved' | 'failedOrDisagreement'>;
}

export function StatsCard({ keyIndicators }: StatsCardProps) {
  const { key, total, achievedOrApproved, failedOrDisagreement } = keyIndicators;
  return (
    <Card title={'Количество'} slotRight={<div className={s.StatsCard__value}>{key}</div>}>
      <RatioBar
        className={s.StatsCard__ratioBar}
        data={[
          { value: achievedOrApproved, color: '#16a086' },
          { value: failedOrDisagreement, color: '#ee3f58' },
          { value: total - key }
        ]}
      />

      <div className={s.StatsCard__pairs}>
        <KeyValuePair valueBold pointColor={'#16a086'} keyLabel={'Утверждено'}>
          {keyIndicators.achievedOrApproved}
        </KeyValuePair>
        <KeyValuePair valueBold pointColor={'#ee3f58'} keyLabel={'Разногласия'}>
          {keyIndicators.failedOrDisagreement}
        </KeyValuePair>
        <KeyValuePair pointColor={'#d1d5df'} keyLabel={'Показателей ВПЦТ'}>
          {keyIndicators.total}
        </KeyValuePair>
      </div>

      <Divider className={s.StatsCard__divider} />
      <SharePDF kind={SharePDFKind.IndicatorsCard} data={keyIndicators}>
        {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
      </SharePDF>
    </Card>
  );
}
