import React, { useState } from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { Label } from 'components/Label';
import { DocumentAttachmentType, DocumentType } from 'store/graphql';
import { DocumentUtils } from 'utils/DocumentUtils';
import { ShareButton } from 'components/ShareButton';
import { Button, ButtonVariant } from 'components/Button';
import { ArrowDown, ArrowUp } from 'components/Icon';
import { Divider } from 'components/Divider';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { DocumentCardContent, DocumentCardContentRequisites } from 'features/DocumentCardContent';
import { Card } from '../Card';
import { DocumentCardAttachments } from './DocumentCardAttachments';
import s from './DocumentCard.module.scss';

export interface DocumentCardProps extends Pick<DocumentType, 'documentName' | 'status' | 'statusText' | 'statusDate'> {
  requisites: DocumentCardContentRequisites[];
  showOnlyRequisites?: boolean;
  requisitesTitle?: string;
  showRequisites?: boolean;
  showRequisitesButton?: boolean;
  attachments?: DocumentAttachmentType[] | null;
}

export const DocumentCard = ({
  documentName,
  status,
  statusText,
  statusDate,
  requisites,
  showOnlyRequisites,
  requisitesTitle,
  attachments,
  showRequisites: showRequisitesProp = true,
  showRequisitesButton
}: DocumentCardProps) => {
  const [label, labelType] = DocumentUtils.getDocumentStatusLabel(status);
  const [showRequisites, setShowRequisites] = useState(showRequisitesProp);

  return (
    <Card>
      {!showOnlyRequisites && (
        <article>
          <div className={s.DocumentCard__header}>
            <Label type={labelType}>{statusText ?? label}</Label>
            <div className={s.DocumentCard__date}>{formatDate(statusDate, DATE_SHORT_YEAR)}</div>
          </div>

          <p className={s.DocumentCard__description}>{documentName}</p>
        </article>
      )}

      {!showOnlyRequisites && showRequisitesButton && (
        <Button
          className={s.DocumentCard__showRequisites}
          variant={ButtonVariant.Link}
          iconRight={showRequisites ? ArrowUp : ArrowDown}
          classes={{
            icon: s.DocumentCard__showRequisitesIcon
          }}
          onClick={() => setShowRequisites((p) => !p)}>
          {showRequisites ? 'Свернуть' : 'Подробнее'}
        </Button>
      )}

      {showRequisites && (
        <>
          {requisitesTitle && <p className={s.DocumentCard__requisitesTitle}>{requisitesTitle}</p>}
          <DocumentCardContent requisites={requisites} />
          <SharePDF
            kind={SharePDFKind.DocumentCard}
            data={{ documentName, status, statusText, statusDate, showOnlyRequisites, requisitesTitle, requisites }}>
            {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
          </SharePDF>
          {attachments && (
            <>
              <Divider className={s.DocumentCard__divider} />
              <DocumentCardAttachments attachments={attachments} />
            </>
          )}
        </>
      )}
    </Card>
  );
};
