import React from 'react';
import { KeyIndicatorType } from 'store/graphql';
import { IndicatorTable } from 'features/_indicators';
import s from './NestedIndicator.module.scss';

export interface NestedIndicatorProps {
  indicator: Pick<
    KeyIndicatorType,
    'code' | 'name' | 'valueInfo' | 'color' | 'valueFor2022' | 'valueFor2023' | 'valueFor2024' | 'valueFor2025'
  >;
}

export function NestedIndicator({ indicator }: NestedIndicatorProps) {
  return (
    <article>
      <div className={s.NestedIndicator__heading}>Показатель</div>
      <div className={s.NestedIndicator__name}>
        <span className={s.NestedIndicator__code}>{`${indicator.code} `}</span>
        <span>{indicator.name}</span>
      </div>

      <IndicatorTable
        unit={indicator.valueInfo}
        color={indicator.color}
        values={[indicator.valueFor2022, indicator.valueFor2023, indicator.valueFor2024, indicator.valueFor2025]}
      />
    </article>
  );
}
