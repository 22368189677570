import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import s from './MetaCard.module.scss';

export interface MetaCardProps {
  to: string;
  children: React.ReactNode;
  label: string;
  background: React.ElementType;
  loading?: boolean;
}

export function MetaCard({ to, children, label, background: Background, loading }: MetaCardProps) {
  return (
    <Link className={clsx(s.MetaCard, { [s.MetaCard_loading]: loading })} to={to}>
      <Background className={s.MetaCard__background} />
      {!loading && <div className={s.MetaCard__content}>{!!children || children === 0 ? children : '—'}</div>}
      <div className={s.MetaCard__label}>{label}</div>
    </Link>
  );
}
