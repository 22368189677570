import clsx from 'clsx';
import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { EventType } from 'store/graphql';
import { Divider } from 'components/Divider';
import { ShareButton } from 'components/ShareButton';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { EventContent, TagsList } from 'features/_events';
import { File } from 'features/File';
import s from './Event.module.scss';

export interface EventProps {
  event: EventType;
}

export function Event({ event }: EventProps) {
  const { header, content, tags, publishDate, attachments } = event;

  return (
    <div className={s.Event}>
      <header className={s.Event__title}>{header}</header>
      <div className={clsx(s.Event__header, tags.length > 2 && s.Event__header_column)}>
        <TagsList tags={tags} />
        <p className={s.Event__date}>{formatDate(publishDate, DATE_SHORT_YEAR)}</p>
      </div>
      <div className={s.Event__content}>
        <EventContent content={content} />
        <Divider />

        {!!attachments.length && (
          <>
            <div className={s.Event__attachments}>
              <p className={s.Event__attachmentsTitle}>Приложенные документы</p>
              {attachments.map(({ id, fullName, fileName, fileType, size, createdAt }) => (
                <File
                  key={id}
                  fullName={fullName}
                  fileId={id}
                  fileName={fileName}
                  fileNameOverflow={false}
                  fileType={fileType}
                  size={size}
                  updatedAt={createdAt}
                />
              ))}
            </div>
            <Divider />
          </>
        )}

        <SharePDF kind={SharePDFKind.Event} data={event}>
          {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
        </SharePDF>
      </div>
    </div>
  );
}
