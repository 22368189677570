import clsx from 'clsx';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Button, ButtonVariant } from 'components/Button';
import { ErrorMessage } from './ErrorMessage';
import s from './PageError.module.scss';

export interface PageErrorProps {
  className?: string;
  errorTitle?: string;
  errorDescription?: string;
  reloadAvailable?: boolean;
}

export function PageError({ className, errorTitle, errorDescription, reloadAvailable }: PageErrorProps) {
  // TODO: передавать сюда ApolloError и генерировать разное описание на ее основе
  const homePage = useMatch('/');

  return (
    <div className={clsx(s.PageError, className)}>
      <ErrorMessage title={errorTitle ?? 'Не удалось получить данные'} description={errorDescription} />

      <div className={s.PageError__actions}>
        {!homePage && (
          <Button className={s.PageError__actionButton} variant={ButtonVariant.Secondary} Component={Link} to={'/'}>
            На главную
          </Button>
        )}

        {(reloadAvailable ?? true) && (
          <Button className={s.PageError__actionButton} onClick={() => window.location.reload()}>
            Обновить
          </Button>
        )}
      </div>
    </div>
  );
}
