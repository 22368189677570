import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useIndicatorPageQuery } from 'store/graphql';
import { PageTitle } from 'components/PageTitle';
import { Loader } from 'components/Loader';
import { PageError } from 'components/ErrorMessage';
import { Label, LabelType } from 'components/Label';
import { Button, ButtonVariant } from 'components/Button';
import { Share } from 'components/Icon';
import { DropdownArrow } from 'components/DropdownArrow';
import { ToggleTabs } from 'components/ToggleTabs';
import { Card, Position, IndicatorTable, convertDataToShare } from 'features/_indicators';
import { useShareTextData } from 'hooks/useShareTextData';
import { NestedIndicator } from './NestedIndicator';
import { HistoryItem } from './HistoryItem';
import s from './IndicatorPage.module.scss';

enum TabEnum {
  Status = 'status',
  History = 'history'
}

const getLabel = (criteria: boolean): [LabelType, string] =>
  criteria ? [LabelType.Success, 'Утверждено'] : [LabelType.Error, 'Разногласия'];

export function IndicatorPage() {
  const [showNested, setShowNested] = useState(true);
  const [activeTab, setActiveTab] = useState(TabEnum.Status);
  const { indicatorId } = useParams<{ indicatorId: string }>();
  const handleShareText = useShareTextData();

  const { loading, error, data } = useIndicatorPageQuery({ variables: { ids: [+(indicatorId as string)] } });
  const indicator = data?.dashboard.keyIndicators.indicators[0];
  const history = indicator?.history;

  const tabs = useMemo(() => {
    return [
      { label: 'Статус', value: TabEnum.Status },
      { label: 'История', value: TabEnum.History, disabled: !history?.length }
    ];
  }, [history]);

  const handleShare = () => {
    if (!indicator) return;
    handleShareText(convertDataToShare(indicator));
  };

  if (loading) return <Loader />;
  if (!data || !!error) return <PageError />;

  if (!indicator) return <Navigate to={'/indicators'} />;

  const [labelType, label] = getLabel(indicator.approved);
  const interactive = indicator.haveNested;

  return (
    <React.Fragment>
      <PageTitle backTo={'/indicators'}>Ключевой показатель</PageTitle>

      <div className={s.IndicatorPage}>
        <ToggleTabs className={s.IndicatorPage__toggleTabs} options={tabs} value={activeTab} onChange={setActiveTab} />

        {activeTab === TabEnum.Status && (
          <Card title={'Статус'} slotRight={<Label type={labelType}>{label}</Label>}>
            <div className={s.IndicatorPage__positions}>
              <Position approved={indicator.approvedFromMc}>Позиция Минцифры России</Position>
              <Position approved={indicator.approvedFromOgv}>Позиция ведомства</Position>
            </div>

            <div
              onClick={() => interactive && setShowNested((showNested) => !showNested)}
              className={clsx(s.IndicatorPage__indicator, interactive && s.IndicatorPage__indicator_interactive)}>
              <div className={s.IndicatorPage__name}>
                <span className={s.IndicatorPage__code}>{`${indicator.code} `}</span>
                <span>{indicator.name}</span>
              </div>

              {interactive && (
                <div className={s.IndicatorPage__dropdownArrow}>
                  <DropdownArrow small isOpen={showNested} />
                </div>
              )}
            </div>

            {!indicator.haveNested && (
              <IndicatorTable
                className={s.IndicatorPage__table}
                unit={indicator.valueInfo}
                color={indicator.color}
                values={[
                  indicator.valueFor2022,
                  indicator.valueFor2023,
                  indicator.valueFor2024,
                  indicator.valueFor2025
                ]}
              />
            )}

            {indicator.haveNested && !!indicator.nestedIndicators.length && showNested && (
              <div className={s.IndicatorPage__nested}>
                {indicator.nestedIndicators.map((indicator) => (
                  <NestedIndicator key={indicator.id} indicator={indicator} />
                ))}
              </div>
            )}

            <Button
              className={s.IndicatorPage__share}
              iconLeft={Share}
              variant={ButtonVariant.Link}
              onClick={handleShare}>
              Поделиться
            </Button>
          </Card>
        )}
        {activeTab === TabEnum.History && (
          <Card title={'История изменения'}>
            {history?.map((item) => (
              <div key={item.id} className={s.HistoryList__item}>
                <HistoryItem {...item} />
              </div>
            ))}
          </Card>
        )}
      </div>
    </React.Fragment>
  );
}
