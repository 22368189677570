import React, { ChangeEvent } from 'react';
import { Check, Icon, IconSize } from 'components/Icon';
import { Switcher } from 'components/Switcher';
import { Loader, LoaderSize } from 'components/Loader';
import s from './Position.module.scss';

export interface PositionProps {
  children: string;
  approved: boolean;
  loading?: boolean;
  onChange?: (value: boolean, e: ChangeEvent) => void;
}

export function Position({ children, approved, loading, onChange }: PositionProps) {
  return (
    <div className={s.Position}>
      <div className={s.Position__source}>{children}</div>
      <div className={s.Position__statusContainer}>
        {!!onChange ? (
          loading ? (
            <Loader className={s.Position__loader} size={LoaderSize.Small} />
          ) : (
            <Switcher value={approved} onChange={onChange} />
          )
        ) : (
          <div className={s.Position__check}>
            {approved && <Icon className={s.Position__checkIcon} icon={Check} size={IconSize.Small} />}
          </div>
        )}
      </div>
    </div>
  );
}
