import { DocumentStatusEnum, DocumentTypeEnum } from 'store/graphql';
import { LabelType } from 'components/Label';

export class DocumentUtils {
  static getDocumentStatusLabel(status: DocumentStatusEnum): [string, LabelType] {
    switch (status) {
      case DocumentStatusEnum.Executed:
        return ['Исполнено', LabelType.Success];
      case DocumentStatusEnum.ExecutedBySending:
        return ['Исполнено по досылу', LabelType.Success];
      case DocumentStatusEnum.Expertise:
        return ['На экспертизе', LabelType.Warning];
      case DocumentStatusEnum.Agreement:
        return ['Подписание', LabelType.Warning];
      case DocumentStatusEnum.Signing:
        return ['На подписании', LabelType.Warning];
      case DocumentStatusEnum.Queue:
        return ['В очереди', LabelType.Neutral];
      default:
        return ['Другое', LabelType.Neutral];
    }
  }

  static getDocumentType(documentType: DocumentTypeEnum): string {
    switch (documentType) {
      case DocumentTypeEnum.TechnicalTask:
        return 'Оценка ТЗ';
      case DocumentTypeEnum.Concept:
        return 'Концепция';
      case DocumentTypeEnum.RegulatoryAct:
        return 'Оценка НПА';
      case DocumentTypeEnum.Addition:
        return 'Оценка доп. финансирования';
      case DocumentTypeEnum.Roadmap:
        return 'Дорожная карта';
      default:
        return 'Другое';
    }
  }
}
