import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { FOIVList, Icon } from 'components/Icon';
import { ReactComponent as Logo } from './logo.svg';
import s from './Header.module.scss';

export interface HeaderProps {
  department: string;
  departmentList: boolean;
  showDepartmentList: () => void;
}

export function Header({ department, departmentList, showDepartmentList }: HeaderProps) {
  return (
    <header className={s.Header}>
      <div className={s.Header__content}>
        <Link to={'/'}>
          <Logo />
        </Link>

        <div className={s.Header__department}>{department}</div>

        <Icon
          icon={FOIVList}
          // увеличенная зона нажатия
          style={{ width: 40, height: 40, padding: 8 }}
          className={clsx(s.Header__burger, departmentList && s.Header__burger_active)}
          onClick={showDepartmentList}
        />
      </div>
    </header>
  );
}
