var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isNumberLike } from './tryNumberLike';
import { getNumberSign } from './numberSign';
const defaultIntlLocale = 'RU-ru';
export function formatNumberIntl(value, options = {}) {
    if (!isNumberLike(value))
        return '';
    const { locale, addSign, formatPercent } = options, restOptions = __rest(options, ["locale", "addSign", "formatPercent"]);
    const intl = Intl.NumberFormat(locale || defaultIntlLocale, restOptions);
    function format(val) {
        return addSign ? `${getNumberSign(val)} ${intl.format(Math.abs(val))}` : intl.format(val);
    }
    if (formatPercent) {
        return format(value * 100) + '%';
    }
    return format(value);
}
