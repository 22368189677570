import React from 'react';
import { RatingPageQuery } from 'store/graphql';
import { RatingTitle } from 'features/_rating/RatingTitle';
import { GradientRatioBar } from 'features/_rating/GradientRatioBar';
import s from './RatingHeader.module.scss';

interface RatingHeaderProps {
  data: RatingPageQuery;
}

export function RatingHeader({ data }: RatingHeaderProps) {
  const { lastUpdate, pointsAchieved, relativeBarDynamicValue } = data.dashboard.operationalRating;

  return (
    <div className={s.RatingHeader}>
      <RatingTitle title={'Баллы'} value={pointsAchieved || 0} lastUpdate={lastUpdate} />
      <div className={s.RatingHeader__group}>Группа эффективности</div>
      <GradientRatioBar relativeValue={relativeBarDynamicValue || 0} scaleLinesCount={2} />
      <div className={s.RatingHeader__groupTypes}>
        <div className={s.RatingHeader__groupType}>Низкая</div>
        <div className={s.RatingHeader__groupType}>Средняя</div>
        <div className={s.RatingHeader__groupType}>Высокая</div>
      </div>
    </div>
  );
}
