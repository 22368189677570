import React from 'react';
import clsx from 'clsx';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { Icon, Hide as HideIcon, Show as ShowIcon, Kebab as KebabIcon } from '../../../components/Icon';
import { useWidgetSettings } from '../context/useWidgetSettings';
import { WidgetSettingsType } from '../../../store/graphql';
import s from './SettingsCard.module.scss';

type Props = {
  item: WidgetSettingsType;
};

export function SettingsCard({ item }: Props) {
  const { attributes, isDragging, listeners, transform, setNodeRef: setDraggableRef } = useDraggable({ id: item.id });
  const { isOver, setNodeRef: setDroppableRef } = useDroppable({ id: item.id });
  const isDragOver = item.changeable && isOver;
  const { hideItem, showItem } = useWidgetSettings();

  const toggleVisible = () => {
    if (item.visible) {
      hideItem(item);
    } else {
      showItem(item);
    }
  };

  const renderControls = () => {
    if (!item.changeable) return null;
    return (
      <div className={s.SettingsCard__iconsBlock} onClick={toggleVisible}>
        {renderVisibilityControl()}
        <div {...attributes} {...listeners} className={s.SettingsCard__iconWrapper} role="button">
          <Icon icon={KebabIcon} className={clsx(s.SettingsCard__icon, !item.visible && s.SettingsCard__hidden)} />
        </div>
      </div>
    );
  };

  const renderVisibilityControl = () => {
    return (
      <div className={s.SettingsCard__eyeBlock}>
        <Icon icon={item.visible ? HideIcon : ShowIcon} className={s.SettingsCard__icon} />
      </div>
    );
  };

  const style = clsx(
    s.SettingsCard,
    isDragOver && !isDragging && s.SettingsCard__drop,
    isDragging && s.SettingsCard__dragging
  );

  const transformStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
      }
    : undefined;

  return (
    <div
      ref={!isDragging && item.changeable ? setDroppableRef : setDraggableRef}
      className={style}
      style={transformStyle}>
      <div className={clsx(s.SettingsCard__row, !item.visible && s.SettingsCard__hidden)}>
        {item.name}
        {renderControls()}
      </div>
    </div>
  );
}
