import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHomePageQuery } from 'store/graphql';
import { PageError } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { MetaCardList } from './MetaCardList';
import { NotificationList } from './NotificationList';
import { WidgetCardsList } from './_components/WidgetCardsList';
import s from './HomePage.module.scss';

export function HomePage() {
  const { loading, data } = useHomePageQuery({
    variables: {
      skipFinancialAnalysis: true
    }
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Главная</title>
      </Helmet>

      <MetaCardList />

      <div className={s.HomePage}>
        {loading ? (
          <Loader />
        ) : !data ? (
          <PageError className={s.HomePage__error} />
        ) : (
          <>
            <WidgetCardsList data={data} />
            <NotificationList
              requests={data.dashboard.notifications.requests}
              documents={data.dashboard.documents.items}
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
}
