import { useInstallPrompt } from 'store/installPrompt';
import { rnMessenger } from 'store/rnMessenger';

export const useInstallPromptButton = () => {
  const { canPrompt, promptDialogShowing, prompt } = useInstallPrompt();

  const promptButtonVisible = !rnMessenger.isActive && (canPrompt || promptDialogShowing);

  return {
    promptButtonVisible,
    promptButtonDisabled: promptDialogShowing,
    prompt
  };
};
