import clsx from 'clsx';
import { TagWithCountType } from 'store/graphql';
import { Tab } from 'components/Tabs';
import { Button, ButtonVariant } from 'components/Button';
import { ArrowDown, ArrowUp } from 'components/Icon';
import s from './Tags.module.scss';

export interface TagsProps {
  tags: Omit<TagWithCountType, 'count'>[];
  showAll: boolean;
  onChangeShowAll: (showAll: boolean) => void;
  selectedTags: number[] | null;
  onSelectedTagsChange: (val: number[] | null) => void;
}

export function Tags({ tags, showAll, selectedTags, onChangeShowAll, onSelectedTagsChange }: TagsProps) {
  return (
    <div className={s.Tags}>
      <div className={clsx(s.Tags__content, !showAll && s.Tags__content_collapsed)}>
        <Tab
          id={'all'}
          label={'Все'}
          isActive={!selectedTags || selectedTags.length === 0}
          onChange={() => onSelectedTagsChange(null)}
        />
        {tags.map(({ id, name, colorHex }) => (
          <Tab
            key={id}
            id={id}
            isActive={selectedTags?.includes(id)}
            label={name}
            dotColor={colorHex}
            onChange={() => {
              if (selectedTags?.includes(id)) {
                return;
              }
              onSelectedTagsChange([...(selectedTags ?? []), id]);
            }}
            onDismiss={() => {
              if (selectedTags?.includes(id)) {
                const result = selectedTags.filter((tagId) => tagId !== id);

                if (result.length === 0) {
                  onSelectedTagsChange(null);
                  return;
                }

                onSelectedTagsChange(result);
              }
            }}
          />
        ))}
      </div>

      {tags.length > 1 && (
        <Button
          className={s.Tags__showMore}
          variant={ButtonVariant.Link}
          iconRight={showAll ? ArrowUp : ArrowDown}
          onClick={() => onChangeShowAll(!showAll)}>
          {showAll ? 'Свернуть' : 'Все теги'}
        </Button>
      )}
    </div>
  );
}
