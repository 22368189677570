import clsx from 'clsx';
import React from 'react';
import { Warning } from 'components/Icon';
import s from './ErrorMessage.module.scss';

export interface ErrorMessageProps {
  title?: string;
  description?: React.ReactNode;
  className?: string;
}

export function ErrorMessage({
  title,
  description = 'Попробуйте перезапустить приложение или зайдите через некоторое время',
  className
}: ErrorMessageProps) {
  return (
    <div className={clsx(s.ErrorMessage, className)}>
      <Warning className={s.ErrorMessage__icon} />
      {title && <h4 className={s.ErrorMessage__title}>{title}</h4>}
      <p className={s.ErrorMessage__description}>{description}</p>
    </div>
  );
}
