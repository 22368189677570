var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { tryParseJSON, singleton } from '@digital-gov/ui-utils';
import { GET_TOKEN, REFRESH_TOKEN } from './graphql';
import { apiUrl, dashboardBlitzClientId, isProduction, ratingBlitzClientId } from '../config';
import { AppScope } from '../AppScope';
import { AuthContext } from './AuthContext';
import { parseAuthResponse } from './parseAuthResponse';
import { ApolloClient, InMemoryCache } from '@apollo/client';
const getClientId = (scope) => {
    if (scope === AppScope.Dashboard)
        return dashboardBlitzClientId;
    return ratingBlitzClientId;
};
const getRedirectPathname = (scope) => {
    if (scope === AppScope.Rating)
        return '/rating/auth';
    if (scope === AppScope.Annuary)
        return '/rating/year/auth';
    return '/baza/auth';
};
const getApiUrl = (scope) => {
    if (apiUrl)
        return apiUrl;
    if (scope === AppScope.Dashboard)
        return '/baza/api/graphql';
    return '/rating/api/graphql';
};
const getAuthScope = (scope) => {
    if (scope === AppScope.Dashboard)
        return 'Dashboard';
    return 'Rating';
};
export class AuthProvider extends React.Component {
    constructor(props) {
        super(props);
        this.clientId = getClientId(this.props.scope);
        this.redirectPathname = getRedirectPathname(this.props.scope);
        this.apiUrl = getApiUrl(this.props.scope);
        this.authScope = getAuthScope(this.props.scope);
        this.authClient = new ApolloClient({
            uri: this.apiUrl,
            cache: new InMemoryCache()
        });
        // сырые данные из localStorage
        this.storageData = localStorage.getItem(`authData-${this.authScope}`);
        // отвечает за синхронизацию this.storageData и localStorage
        this.setStorageData = (storageData) => {
            if (!storageData) {
                localStorage.removeItem(`authData-${this.authScope}`);
                this.storageData = null;
            }
            else {
                localStorage.setItem(`authData-${this.authScope}`, storageData);
                this.storageData = storageData;
            }
        };
        this.handleStorageChange = () => __awaiter(this, void 0, void 0, function* () {
            const storageData = localStorage.getItem(`authData-${this.authScope}`);
            if (storageData !== this.storageData) {
                this.storageData = storageData;
                this.setState({ authData: tryParseJSON(this.storageData), authCode: null });
            }
        });
        this.setToken = (tokenData) => new Promise((resolve) => {
            const { accessToken, expiresIn, refreshToken } = tokenData;
            const authData = { accessToken, expires: Date.now() + expiresIn * 1000 * 0.85, refreshToken };
            this.setStorageData(JSON.stringify(authData));
            this.setState({ authData, authCode: null }, () => resolve(authData.accessToken));
        });
        this.requestToken = singleton((authCode) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.authClient.query({
                    fetchPolicy: 'no-cache',
                    query: GET_TOKEN,
                    variables: {
                        input: {
                            code: authCode,
                            redirectUri: `${window.location.origin}${this.redirectPathname}`,
                            scope: this.authScope
                        }
                    }
                });
                return this.setToken(data.getTokens);
            }
            catch (error) {
                if (!!(error === null || error === void 0 ? void 0 : error.networkError)) {
                    this.logOut('Ошибка соединения с API-сервисом');
                }
                else {
                    // isForced in production
                    this.logOut('Не удалось получить маркер доступа', isProduction);
                }
                return null;
            }
        }));
        this.refreshToken = singleton((refreshToken) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            try {
                const { data } = yield this.authClient.query({
                    fetchPolicy: 'no-cache',
                    query: REFRESH_TOKEN,
                    variables: { input: { refreshToken, scope: this.authScope } }
                });
                return this.setToken(data.refreshTokens);
            }
            catch (error) {
                // проверила, что с сервера возвращается именно такая ошибка
                if (((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.path) === null || _c === void 0 ? void 0 : _c[0]) === 'refreshTokens')
                    this.logOut('Не удалось обновить маркер доступа');
                return null;
            }
        }));
        this.getToken = () => __awaiter(this, void 0, void 0, function* () {
            if (this.state.authCode && !this.state.authData) {
                return this.requestToken(this.state.authCode);
            }
            else if (this.state.authData) {
                if (this.state.authData.expires < Date.now())
                    return this.refreshToken(this.state.authData.refreshToken);
                return this.state.authData.accessToken;
            }
            else {
                this.logIn();
                return null;
            }
        });
        this.isLoggedIn = () => !!this.state.authData || !!this.state.authCode;
        this.logIn = () => {
            // выдает Forbidden при повторной попытке перейти по ссылке
            // какой-то rate limiting, завязанный на сессии (private-режим помогает)
            window.location.href =
                'https://login.eskigov.ru/blitz/oauth/ae' +
                    '?response_type=code&scope=openid%20email' +
                    `&client_id=${this.clientId}` +
                    `&redirect_uri=${window.location.origin}${this.redirectPathname}`;
        };
        this.logOut = (error, isForced) => {
            if (error) {
                this.setStorageData(null);
                this.setState({ authData: null, authCode: null, error });
            }
            if (!error || isForced) {
                // полноценный выход из Blitz
                window.location.href =
                    'https://login.eskigov.ru/blitz/login/logout' +
                        `?client_id=${this.clientId}` +
                        `&post_logout_redirect_uri=${window.location.origin}${this.redirectPathname}`;
            }
        };
        const state = {
            apiUrl: this.apiUrl,
            authData: tryParseJSON(this.storageData),
            authCode: null,
            error: null,
            getToken: this.getToken,
            isLoggedIn: this.isLoggedIn,
            logIn: this.logIn,
            logOut: this.logOut
        };
        // Blitz response
        // TODO: подумать, как избежать случайного выхода
        if (window.location.pathname === this.redirectPathname) {
            state.authData = null;
            this.setStorageData(null);
            const { code, error } = parseAuthResponse();
            state.authCode = code;
            state.error = error && 'Ошибка авторизации от Blitz';
        }
        this.state = state;
    }
    componentDidMount() {
        // подписка на изменения в localStorage, чтобы отслеживать изменения из других вкладок
        window.addEventListener('storage', this.handleStorageChange);
        // pre-fetch
        this.getToken();
    }
    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }
    render() {
        return React.createElement(AuthContext.Provider, { value: this.state }, this.props.children);
    }
}
