import clsx from 'clsx';
import React, { useState } from 'react';
import s from './ToggleTabs.module.scss';

export interface ToggleTabsProps<V = string> {
  className?: string;
  options: {
    label: string;
    value: V;
    disabled?: boolean;
  }[];
  value?: V;
  onChange?: (value: V) => void;
}

export const ToggleTabs = <V,>({ className, options, value, onChange }: ToggleTabsProps<V>) => {
  const getActiveIndex = (value?: V) => {
    return Math.max(
      options.findIndex((o) => o.value === value),
      0
    );
  };

  const [activeIndex, setActiveIndex] = useState(getActiveIndex(value));

  const handleChange = (value: V) => {
    setActiveIndex(getActiveIndex(value));
    onChange?.(value);
  };

  return (
    <div className={clsx(s.Tabs__wrap, className)}>
      <div className={s.Tabs__sliderBox}>
        <div
          className={s.Tabs__slider}
          style={{ width: `${100 / options.length}%`, transform: `translateX(${100 * activeIndex}%)` }}
        />
      </div>

      {options.map((o, index) => (
        <button
          key={String(o.value)}
          className={clsx(s.Tabs__item, {
            [s.Tabs__item_active]: index === activeIndex,
            [s.Tabs__item_disabled]: o.disabled
          })}
          onClick={() => !o.disabled && handleChange(o.value)}>
          {o.label}
        </button>
      ))}
    </div>
  );
};
