import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { from, useApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { EMDASH } from '@digital-gov/ui-utils';
import { useAuthorityQuery } from 'store/graphql';
import { AuthorityContext } from './AuthorityContext';

export function AuthorityProvider({ children }: { children: React.ReactNode }) {
  const apolloClient = useApolloClient();
  const { loading: queryLoading, error, data } = useAuthorityQuery();
  const [authority, setAuthority] = useState(Number(localStorage.getItem('authority')) || null);
  const [loading, setLoading] = useState(false);

  const profile = data?.dashboard.profile;
  const current = profile?.availableDepartments.find((d) => d.authority === authority) || profile?.department;

  useLayoutEffect(() => {
    const apolloLink = apolloClient.link;

    if (current?.authority) {
      const authorityLink = setContext((_, { headers }) => ({
        headers: { ...headers, 'X-Authority': current.authority }
      }));

      apolloClient.setLink(from([authorityLink, apolloLink]));
    }

    return () => apolloClient.setLink(apolloLink);
  }, [apolloClient, current?.authority]);

  useLayoutEffect(() => {
    if (authority && !!current) {
      localStorage.setItem('authority', authority.toString());

      setLoading(true);
      apolloClient
        .resetStore()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloClient, authority]);

  const value = useMemo(
    () => ({
      loading: loading || queryLoading,
      error,
      authority: current?.authority || null,
      setAuthority,
      department: current?.departmentShortName || EMDASH,
      departmentFullName: current?.departmentFullName || EMDASH,
      accessToTagLibraryMaterials: profile?.accessToTagLibraryMaterials ?? false,
      availableDepartments:
        profile?.availableDepartments.map(({ authority, departmentShortName, departmentFullName }) => ({
          authority,
          department: departmentShortName,
          departmentFullName,
          active: authority === current?.authority
        })) || []
    }),
    [error, loading, queryLoading, profile, current]
  );

  useLayoutEffect(() => {
    // автоматический переход на legacy-версию
    if (process.env.NODE_ENV === 'production' && profile?.legacy) window.location.href = '/current';
  }, [profile?.legacy]);

  useEffect(() => {
    // Yandex.Metrika
    // @ts-ignore
    if (profile?.id) window.ym?.('93813966', 'userParams', { UserID: profile.id });
  }, [profile?.id]);

  return <AuthorityContext.Provider value={value}>{children}</AuthorityContext.Provider>;
}
