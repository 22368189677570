import React from 'react';
import { KeyIndicatorsType } from 'store/graphql';
import { KeyValuePair } from 'components/KeyValuePair';
import { RatioBar } from 'components/RatioBar';
import { Divider } from 'components/Divider';
import { ShareButton } from 'components/ShareButton';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { WidgetCard } from 'features/Widgets/_components/WidgetCard';
import s from './IndicatorsCard.module.scss';

export interface IndicatorsCardProps {
  keyIndicators: Pick<KeyIndicatorsType, 'key' | 'achievedOrApproved' | 'failedOrDisagreement' | 'total'>;
}

export function IndicatorsCard({ keyIndicators }: IndicatorsCardProps) {
  return (
    <WidgetCard to={'/indicators'}>
      <div className={s.IndicatorsCard__header}>
        <h3 className={s.IndicatorsCard__heading}>Ключевые показатели</h3>
        <div className={s.IndicatorsCard__indicators}>{keyIndicators.key}</div>
      </div>

      <RatioBar
        className={s.IndicatorsCard__ratioBar}
        data={[
          { value: keyIndicators.achievedOrApproved, color: '#16a086' },
          { value: keyIndicators.failedOrDisagreement, color: '#ee3f58' },
          { value: keyIndicators.total - keyIndicators.key }
        ]}
      />

      <div className={s.IndicatorsCard__pairs}>
        <KeyValuePair valueBold pointColor={'#16a086'} keyLabel={'Утверждено'}>
          {keyIndicators.achievedOrApproved}
        </KeyValuePair>
        <KeyValuePair valueBold pointColor={'#ee3f58'} keyLabel={'Разногласия'}>
          {keyIndicators.failedOrDisagreement}
        </KeyValuePair>
        <KeyValuePair pointColor={'#d1d5df'} keyLabel={'Показателей ВПЦТ'}>
          {keyIndicators.total}
        </KeyValuePair>
      </div>

      <Divider className={s.IndicatorsCard__divider} />
      <SharePDF kind={SharePDFKind.IndicatorsCard} data={keyIndicators}>
        {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
      </SharePDF>
    </WidgetCard>
  );
}
