import React, { useState } from 'react';
import { getRatingIndicatorPDF } from 'utils/getRatingIndicatorPDF';
import { OperationalRatingIndicatorType } from 'store/graphql';
import { RatingTitle } from 'features/_rating/RatingTitle';
import { RatingChartBlock } from 'features/_rating/RatingChartBlock';
import { PDFViewer } from 'features/PDFViewer/PDFViewer';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { Button, ButtonVariant } from 'components/Button';
import { Link as LinkIcon } from 'components/Icon';
import { Divider } from 'components/Divider';
import { ShareButton } from 'components/ShareButton';
import { useExternalLink } from '../../../hooks/useExternalLink';
import s from './RatingIndicatorContent.module.scss';

interface RatingIndicatorContentProps {
  lastUpdate?: string | null;
  item: OperationalRatingIndicatorType;
}

export function RatingIndicatorContent({ item, lastUpdate }: RatingIndicatorContentProps) {
  const { id, updatePeriodicity, responsibleName, responsibleEmail, sourceName } = item;

  const [pdfOpen, setPdfOpen] = useState(false);
  const handleMailTo = useExternalLink();

  const renderIndicatorBlock = (title: string, content: React.ReactNode) => {
    return (
      <div className={s.RatingIndicatorContent__indicatorBlock}>
        <div className={s.RatingIndicatorContent__indicatorTitle}>{title}</div>
        {content}
      </div>
    );
  };

  const renderLinksBlock = () => {
    const [href] = getRatingIndicatorPDF(id);
    return (
      <div className={s.RatingIndicatorContent__linksBlock}>
        <Button variant={ButtonVariant.Link} iconLeft={LinkIcon} onClick={() => setPdfOpen(true)}>
          Посмотреть формулу расчета
        </Button>

        <Divider className={s.RatingIndicatorContent__linksDivider} />
        <SharePDF kind={SharePDFKind.RatingIndicator} data={{ lastUpdate, indicator: item }}>
          {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
        </SharePDF>

        <PDFViewer title={'Формула расчета'} href={href} open={pdfOpen} onClose={() => setPdfOpen(false)} />
      </div>
    );
  };

  const renderDepartmentBlock = () => {
    if (!sourceName) return null;
    return renderIndicatorBlock(
      'Департамент',
      <div className={s.RatingIndicatorContent__indicatorText}>{sourceName}</div>
    );
  };

  const renderPeriodicity = () => {
    if (!updatePeriodicity) return null;
    return renderIndicatorBlock(
      'Периодичность обновления',
      <div className={s.RatingIndicatorContent__indicatorText}>{updatePeriodicity}</div>
    );
  };

  const renderResponsibleBlock = () => {
    if (!responsibleName) return null;
    return renderIndicatorBlock(
      'Ответственный',
      <>
        <div className={s.RatingIndicatorContent__indicatorText}>{responsibleName}</div>
        {responsibleEmail && (
          <Button
            className={s.RatingIndicatorContent__indicatorLink}
            onClick={() => handleMailTo(`mailto:${responsibleEmail}`)}
            variant={ButtonVariant.Link}>
            {responsibleEmail}
          </Button>
        )}
      </>
    );
  };

  const renderChartBlock = () => {
    if (!item.chart.length) return null;
    return (
      <div className={s.RatingIndicatorContent__chartBlock}>
        <RatingChartBlock maxValue={item.maxValue} chart={item.chart} />
      </div>
    );
  };

  return (
    <div>
      <RatingTitle title={item.name} value={item.currentValue || 0} lastUpdate={lastUpdate} dynamics={item.dynamic} />
      <div className={s.RatingIndicatorContent__spacer} />
      {renderDepartmentBlock()}
      {renderResponsibleBlock()}
      {renderPeriodicity()}
      {renderChartBlock()}
      {renderLinksBlock()}
    </div>
  );
}
