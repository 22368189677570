import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { DocumentSnapshotType } from 'store/graphql';
import { DocumentUtils } from 'utils/DocumentUtils';
import { Label } from '../_components/Label';
import { ExportInfo } from '../_components/ExportInfo';
import s from './DocumentHistoryCard.module.scss';

export interface DocumentHistoryCardData extends DocumentSnapshotType {}

export function DocumentHistoryCard({ document }: { document: DocumentHistoryCardData }) {
  const { date, documentName, status, statusText } = document;
  const [label, labelType] = DocumentUtils.getDocumentStatusLabel(status);

  return (
    <React.Fragment>
      <div className={s.DocumentHistoryCard__header}>
        <Label type={labelType}>{statusText ?? label}</Label>
        <div className={s.DocumentHistoryCard__date}>{formatDate(date, DATE_SHORT_YEAR)}</div>
      </div>

      <ExportInfo />

      <p className={s.DocumentHistoryCard__description}>{documentName}</p>
    </React.Fragment>
  );
}
