import React from 'react';
import { InstallPromptContext, IInstallPrompt, installPromptDefault } from './InstallPromptContext';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export class InstallPromptProvider extends React.Component<{ children: React.ReactNode }, IInstallPrompt> {
  deferredPrompt: IBeforeInstallPromptEvent | null = null;

  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = {
      ...installPromptDefault,
      prompt: this.prompt
    };
  }

  handleBeforeInstallPrompt = (e: IBeforeInstallPromptEvent) => {
    e.preventDefault();
    this.deferredPrompt = e;
    this.setState({ promptDialogShowing: false, canPrompt: true });
  };

  handleAppInstalled = () => {
    this.setState(installPromptDefault);
  };

  prompt = async () => {
    if (!this.state.canPrompt) {
      return;
    }

    this.setState({ canPrompt: false });
    if (!this.deferredPrompt) {
      return;
    }

    this.setState({ promptDialogShowing: true });

    // метод prompt() может быть вызван только один раз,
    // если пользователь отклоняет его, нужно дождаться события beforeinstallprompt,
    // обычно сразу после разрешения свойства userChoice
    this.deferredPrompt.prompt();

    const { outcome } = await this.deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      this.setState(installPromptDefault);
    }
  };

  componentDidMount() {
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt as EventListener);
    window.addEventListener('appinstalled', this.handleAppInstalled);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt as EventListener);
    window.removeEventListener('appinstalled', this.handleAppInstalled);
  }

  render() {
    return <InstallPromptContext.Provider value={this.state}>{this.props.children}</InstallPromptContext.Provider>;
  }
}
