import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconChangeUp } from './assets/i-change-up.svg';
import { ReactComponent as IconChangeDown } from './assets/i-change-down.svg';
import { ReactComponent as IconChangeNone } from './assets/i-change-none.svg';
import s from './Indicator.module.scss';

export enum IndicatorDynamics {
  Positive = 'positive',
  Negative = 'negative',
  None = 'none'
}

const icons = {
  [IndicatorDynamics.Positive]: IconChangeUp,
  [IndicatorDynamics.Negative]: IconChangeDown,
  [IndicatorDynamics.None]: IconChangeNone
};

type statusFormatFunc = () => boolean;

export interface IndicatorProps {
  className?: string;
  successStatusFunc?: statusFormatFunc;
  warningStatusFunc?: statusFormatFunc;
  errorStatusFunc?: statusFormatFunc;
  showChangeIcon?: boolean;
  dynamics?: IndicatorDynamics;
  content?: React.ReactNode;
  children?: React.ReactNode;
  position?: 'left' | 'right';
}

export const Indicator: React.FC<IndicatorProps> = ({
  className,
  showChangeIcon,
  position = 'right',
  dynamics = IndicatorDynamics.None,
  content,
  children
}) => {
  const Icon = showChangeIcon ? icons[dynamics] : null;

  const renderIcon = (className: string) => {
    if (!Icon) return null;
    return <Icon className={clsx(s.Indicator__icon, s[`Indicator__icon_${dynamics}`], className)} />;
  };

  const renderLeftIcon = () => {
    if (position === 'right') return null;
    return renderIcon(s.Indicator__iconRight);
  };

  const renderRightIcon = () => {
    if (position === 'left') return null;
    return renderIcon(s.Indicator__iconLeft);
  };

  return (
    <div className={clsx(s.Indicator, className)}>
      {renderLeftIcon()}
      {content}
      {children}
      {renderRightIcon()}
    </div>
  );
};
