import { DATE_SHORT_YEAR } from '@digital-gov/ui-utils';
import { DocumentType } from 'store/graphql';
import { File } from 'features/File';
import s from './DocumentCardAttachments.module.scss';

export interface DocumentCardAttachmentsProps extends Pick<DocumentType, 'attachments'> {}

export const DocumentCardAttachments = ({ attachments }: DocumentCardAttachmentsProps) => {
  return (
    <div className={s.DocumentCardAttachments}>
      <p className={s.DocumentCardAttachments__header}>Приложенные файлы</p>

      {attachments.length ? (
        attachments.map((attachment) => (
          <File
            key={attachment.id}
            fileId={attachment.id}
            dateFormatString={DATE_SHORT_YEAR}
            fileInfoDivided={true}
            fileInfoReverse={true}
            {...attachment}
          />
        ))
      ) : (
        <p className={s.DocumentCardAttachments__notFound}>Файлов не найдено.</p>
      )}
    </div>
  );
};
