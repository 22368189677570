import React from 'react';
// import { formatNumber } from '@digital-gov/ui-utils';
import { DATE_DAY_MONTH, formatDate } from '@digital-gov/ui-utils';
import { useMetaCardListQuery } from 'store/graphql';
import { MetaCard } from '../_components/MetaCard';
import { ReactComponent as RatingBackground } from './assets/rating.svg';
import { ReactComponent as IndicatorsBackground } from './assets/indicators.svg';
// import { ReactComponent as BudgetBackground } from './assets/budget.svg';
import { ReactComponent as DocumentsBackground } from './assets/documents.svg';
import { ReactComponent as SystemsBackground } from './assets/systems.svg';
import s from './MetaCardList.module.scss';

export function MetaCardList() {
  const { loading, data } = useMetaCardListQuery();
  const { operationalRatingLastUpdate, indicators, documentsCount, keySystemsCount } = data?.dashboard.stats || {};

  return (
    <div className={s.MetaCardList}>
      <div className={s.MetaCardList__content}>
        <MetaCard to={'/rating'} label={'Оперативный рейтинг'} background={RatingBackground} loading={loading}>
          {data && formatDate(operationalRatingLastUpdate, DATE_DAY_MONTH)}
        </MetaCard>

        <MetaCard to={'/indicators'} label={'Ключевые показатели'} background={IndicatorsBackground} loading={loading}>
          {!!indicators && (
            <React.Fragment>
              <span>{indicators.keyIndicatorsCount}</span>
              <span className={s.MetaCardList__sub}> из </span>
              <span>{indicators.totalIndicatorsCount}</span>
            </React.Fragment>
          )}
        </MetaCard>

        {/* <MetaCard to={'/budget'} label={'Свободный остаток, млн'} background={BudgetBackground} loading={loading}> */}
        {/*  {!!budgetBalance && */}
        {/*    // просто они уже несколько раз меняли между собой значения этих переменных */}
        {/*    formatNumber(Math.min(budgetBalance.availableBalance, budgetBalance.totalBalance), 2, true)} */}
        {/* </MetaCard> */}

        <MetaCard to={'/documents'} label={'Документы, шт.'} background={DocumentsBackground} loading={loading}>
          {documentsCount}
        </MetaCard>

        <MetaCard to={'/systems'} label={'Ключевые системы'} background={SystemsBackground} loading={loading}>
          {keySystemsCount}
        </MetaCard>
      </div>

      <div className={s.MetaCardList__scrollIndicator} />
    </div>
  );
}
