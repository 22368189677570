import { useCallback, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { DATE_SHORT_YEAR, formatDate, useShare } from '@digital-gov/ui-utils';
import { useAuthority } from 'store/authority';
import { rnMessenger, sharePDFtoMobileRN } from 'store/rnMessenger';

export interface SharePDFControls {
  share: () => void;
  isLoading: boolean;
}

export function useSharePDF(container: HTMLDivElement | null, name: string = 'Экспорт'): SharePDFControls {
  const { share, canShare } = useShare();
  const { department } = useAuthority();

  const [isLoading, setIsLoading] = useState(false);

  const handleShare = useCallback(async () => {
    if (container) {
      setIsLoading(true);

      try {
        const canvas = await html2canvas(container, { scale: 2, logging: false });
        const data = canvas.toDataURL();

        const PDF = new jsPDF({
          unit: 'px',
          hotfixes: ['px_scaling'],
          orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
          // TODO: PDF для согласования показателей получается больше максимальной высоты (14400)
          format: [canvas.width / 2, canvas.height / 2]
        });

        PDF.addImage(data, 'PNG', 0, 0, canvas.width / 2, canvas.height / 2);
        const filename = `${name} (${department}, ${formatDate(new Date(), DATE_SHORT_YEAR)}).pdf`;

        if (rnMessenger.isActive) {
          const base64 = PDF.output('datauristring');
          setIsLoading(false);
          await sharePDFtoMobileRN(filename, base64);
          return;
        }

        const blob = PDF.output('blob');
        const file = new File([blob], filename, { type: blob.type });

        setIsLoading(false);

        // TODO: постараться воспроизвести и отработать сценарий, когда после загрузки ничего не происходит
        if (!canShare({ files: [file] }) || process.env.NODE_ENV !== 'production') {
          PDF.save(filename);
        } else {
          await share({ files: [file] });
        }
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    }
  }, [container, name, department, canShare, share]);

  return { share: handleShare, isLoading };
}
