import React, { useState } from 'react';
import { SharePDFControls, useSharePDF } from './useSharePDF';
import { ShareLayout } from './_components/ShareLayout';
import { Rating, RatingData } from './_pages/Rating';
import { RatingIndicator, RatingIndicatorData } from './_pages/RatingIndicator';
import { BudgetCard, BudgetCardData } from './_pages/BudgetCard';
import { IndicatorsCard, IndicatorsCardData } from './_pages/IndicatorsCard';
import { Indicators, IndicatorsData } from './_pages/Indicators';
import { DocumentCard, DocumentCardData } from './_pages/DocumentCard';
import { DocumentHistoryCard, DocumentHistoryCardData } from './_pages/DocumentHistoryCard';
import { Systems, SystemsData } from './_pages/Systems';
import { Event, EventData } from './_pages/Event';
import { Request, RequestData } from './_pages/Request';

export enum SharePDFKind {
  Rating = 'Оперативный рейтинг',
  RatingIndicator = 'Показатель рейтинга',
  BudgetCard = 'Карточка бюджета',
  IndicatorsCard = 'Карточка показателей',
  Indicators = 'Показатели',
  DocumentCard = 'Документ',
  DocumentHistoryCard = 'История документа',
  Systems = 'Ключевые системы',
  Event = 'Событие',
  Request = 'Запрос'
}

export type SharePDFProps = { children: (controls: SharePDFControls) => React.ReactNode } & (
  | {
      kind: SharePDFKind.Rating;
      data: RatingData;
    }
  | {
      kind: SharePDFKind.RatingIndicator;
      data: RatingIndicatorData;
    }
  | {
      kind: SharePDFKind.BudgetCard;
      data: BudgetCardData;
    }
  | {
      kind: SharePDFKind.IndicatorsCard;
      data: IndicatorsCardData;
    }
  | {
      kind: SharePDFKind.Indicators;
      data: IndicatorsData;
    }
  | {
      kind: SharePDFKind.DocumentCard;
      data: DocumentCardData;
    }
  | {
      kind: SharePDFKind.DocumentHistoryCard;
      data: DocumentHistoryCardData;
    }
  | {
      kind: SharePDFKind.Systems;
      data: SystemsData;
    }
  | {
      kind: SharePDFKind.Event;
      data: EventData;
    }
  | {
      kind: SharePDFKind.Request;
      data: RequestData;
    }
);

export function SharePDF({ kind, data, children }: SharePDFProps) {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { share, isLoading } = useSharePDF(container, `${kind}`);

  return (
    <React.Fragment>
      {children({ share, isLoading })}

      <ShareLayout ref={setContainer}>
        {kind === SharePDFKind.Rating && <Rating rating={data} />}
        {kind === SharePDFKind.RatingIndicator && <RatingIndicator {...data} />}
        {kind === SharePDFKind.BudgetCard && <BudgetCard budget={data} />}
        {kind === SharePDFKind.IndicatorsCard && <IndicatorsCard indicators={data} />}
        {kind === SharePDFKind.Indicators && <Indicators indicators={data} />}
        {kind === SharePDFKind.DocumentCard && <DocumentCard document={data} />}
        {kind === SharePDFKind.DocumentHistoryCard && <DocumentHistoryCard document={data} />}
        {kind === SharePDFKind.Systems && <Systems keySystems={data} />}
        {kind === SharePDFKind.Event && <Event event={data} />}
        {kind === SharePDFKind.Request && <Request request={data} />}
      </ShareLayout>
    </React.Fragment>
  );
}
