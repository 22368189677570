import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'components/Loader';
import { useServicesPageQuery } from 'store/graphql';
import { ServicesPage } from './ServicesPage';
import { ServiceRouteType, Services, servicesRoutes } from './services/services';
import { IndicatorPage, IndicatorsPage } from './services/indicatorsAgreement';

export const servicesComponents = {
  [Services.Indicators]: IndicatorsPage,
  [Services.Indicator]: IndicatorPage
};

function createRoutesTree(routes: ServiceRouteType[], allowedServices: Services[]) {
  if (!routes.length) return null;

  return routes.map((route, iRoute) => {
    if (!allowedServices.includes(route.id)) return null;

    const Element = servicesComponents[route.id];

    return route.routes?.length ? (
      <Route key={iRoute} path={route.path}>
        <Route index element={<Element />} />
        {createRoutesTree(route.routes || [], allowedServices)}
      </Route>
    ) : (
      <Route key={iRoute} path={route.path} element={<Element />} />
    );
  });
}

export function ServicesRoutes() {
  const { data, loading, error } = useServicesPageQuery();
  const indicatorsAgreementAccess = data?.dashboard.profile.accessToChangeKeyIndicators;

  if (loading) {
    return <Loader />;
  }

  if (!data || error) {
    return <Navigate to={'/services'} />;
  }

  const allowedServices = Object.values(Services).filter((s) => {
    switch (s) {
      case Services.Indicators:
      case Services.Indicator:
        return indicatorsAgreementAccess;
      default:
        return true;
    }
  });

  return (
    <Routes>
      <Route index element={<ServicesPage allowedServices={allowedServices} />} />
      {createRoutesTree(servicesRoutes, allowedServices)}
      <Route path={'*'} element={<Navigate to={'/services'} />} />
    </Routes>
  );
}
