// Оборачивает асинхронную функцию и следит за тем, чтобы она одновременно выполнялась только один раз
export function singleton(func) {
    const worker = (...args) => {
        if (worker.promise) {
            return worker.promise;
        }
        const promise = func(...args);
        if (promise) {
            worker.promise = promise.then((result) => {
                worker.promise = undefined;
                return result;
            }, (error) => {
                worker.promise = undefined;
                throw error;
            });
        }
        else {
            worker.promise = undefined;
        }
        return promise;
    };
    return worker;
}
