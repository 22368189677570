import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useRatingIndicatorPageQuery } from 'store/graphql';
import { Loader } from 'components/Loader';
import { PageError } from 'components/ErrorMessage';
import { PageTitle } from 'components/PageTitle';
import { PageSheet } from 'components/_layouts/PageSheet';
import { RatingIndicatorContent } from './RatingIndicatorContent';
import s from './RatingIndicatorPage.module.scss';

const PageName = 'Показатель рейтинга';

export function RatingIndicatorPage() {
  const { indicatorId } = useParams<{ indicatorId: string }>();
  // TODO: убрать не-используемые поля
  const { loading, data } = useRatingIndicatorPageQuery({
    variables: { indicatorId: [+(indicatorId as string)] }
  });

  if (loading) return <Loader />;
  if (!data) return <PageError />;
  const rating = data.dashboard.operationalRating;
  const item = rating?.operationalRatingIndicators[0];

  // not found
  if (!item) return <Navigate to={'/rating'} replace />;
  return (
    <React.Fragment>
      <Helmet>
        <title>{PageName}</title>
      </Helmet>

      <PageTitle backTo={'/rating'}>{PageName}</PageTitle>
      <PageSheet className={s.RatingIndicatorPage}>
        <RatingIndicatorContent item={item} lastUpdate={rating.lastUpdate} />
      </PageSheet>
    </React.Fragment>
  );
}
