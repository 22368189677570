import clsx from 'clsx';
import React from 'react';
import s from './Icon.module.scss';

export enum IconSize {
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xSmall'
}

export interface IconProps {
  size?: IconSize;
  icon: React.ElementType;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function Icon({ size = IconSize.Medium, icon, className, style, onClick }: IconProps) {
  const IconComponent = icon;
  return (
    <div className={clsx(s.Icon, s[`Icon_${size}`], className)} style={style} onClick={onClick}>
      <IconComponent />
    </div>
  );
}
