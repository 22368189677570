import React, { useState } from 'react';
import { KeyIndicatorType } from 'store/graphql';
import { Tabs } from 'components/Tabs';
import { Divider } from 'components/Divider';
import { LabelType } from 'components/Label';
import { PageSearch } from 'components/PageSearch';
import { ShareButton } from 'components/ShareButton';
import { Card, Indicator } from 'features/_indicators';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import s from './IndicatorListCard.module.scss';

export enum IndicatorListFilter {
  All,
  Key,
  Reached,
  Unreached
}

export interface IndicatorsCountersProps {
  total: number;
  key: number;
  reached: number;
  unreached: number;
}

export interface IndicatorListCardProps {
  counters: IndicatorsCountersProps;
  indicators: Pick<KeyIndicatorType, 'approved' | 'disagreements' | 'isKeyIndicator' | 'code' | 'id' | 'name'>[];
}

const getStatus = (criteria?: boolean) => (criteria ? LabelType.Success : LabelType.Error);

export function IndicatorListCard({ counters, indicators }: IndicatorListCardProps) {
  const [query, setQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState(IndicatorListFilter.All);

  const keyIndicators = indicators.filter((item) => item.isKeyIndicator);
  const reachedIndicators = indicators.filter((item) => item.approved);
  const unReachedIndicators = indicators.filter((item) => item.disagreements);

  const indicatorList = (() => {
    switch (statusFilter) {
      case IndicatorListFilter.Key:
        return keyIndicators;
      case IndicatorListFilter.Unreached:
        return unReachedIndicators;
      case IndicatorListFilter.Reached:
        return reachedIndicators;
      default:
        return indicators;
    }
  })();

  const searchedIndicators = !query
    ? indicatorList
    : indicatorList.filter((item) => `${item.code} ${item.name}`.toLowerCase().includes(query.toLowerCase()));

  const tabs = [
    { id: IndicatorListFilter.All, label: `Все (${counters.total})` },
    { id: IndicatorListFilter.Key, label: `Ключевые (${counters.key})` },
    { id: IndicatorListFilter.Reached, label: `Утверждено (${counters.reached})` },
    { id: IndicatorListFilter.Unreached, label: `Разногласия (${counters.unreached})` }
  ];

  return (
    <Card title={'Показатели'}>
      <Tabs<IndicatorListFilter>
        className={s.IndicatorListCard__tabs}
        value={statusFilter}
        onChange={(statusFilter) => setStatusFilter(statusFilter)}
        options={tabs}
        innerScroll
      />

      <PageSearch
        className={s.IndicatorListCard__search}
        query={query}
        setQuery={setQuery}
        count={searchedIndicators.length}
      />

      <div className={s.IndicatorListCard__list}>
        {searchedIndicators.map((indicator) => (
          <React.Fragment key={indicator.id}>
            <Indicator
              to={indicator.id.toString()}
              code={indicator.code}
              status={getStatus(indicator.approved)}
              statusLabel={indicator.approved ? 'Утверждено' : 'Разногласия'}>
              {indicator.name}
            </Indicator>
            <Divider />
          </React.Fragment>
        ))}

        <SharePDF kind={SharePDFKind.Indicators} data={indicators}>
          {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
        </SharePDF>
      </div>
    </Card>
  );
}
