import { createContext } from 'react';

export const installPromptDefault = {
  canPrompt: false,
  promptDialogShowing: false,
  prompt: () => {}
};

export type IInstallPrompt = typeof installPromptDefault;

export const InstallPromptContext = createContext<IInstallPrompt>(installPromptDefault);
