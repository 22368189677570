import React, { useState } from 'react';
import { KeyIndicatorType } from 'store/graphql';
import { Tabs } from 'components/Tabs';
import { Divider } from 'components/Divider';
import { LabelType } from 'components/Label';
import { PageSearch } from 'components/PageSearch';
import { ShareButton } from 'components/ShareButton';
import { Card, Indicator } from 'features/_indicators';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import s from './IndicatorListCard.module.scss';

export enum IndicatorListFilter {
  All,
  Reached,
  Unreached
}

export interface IndicatorListCardProps {
  indicators: Pick<KeyIndicatorType, 'approved' | 'disagreements' | 'code' | 'id' | 'name'>[];
}

const getStatus = (criteria?: boolean) => (criteria ? LabelType.Success : LabelType.Error);

export function IndicatorListCard({ indicators }: IndicatorListCardProps) {
  const [query, setQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState(IndicatorListFilter.All);

  const reached = indicators.filter((indicator) => indicator.approved).length;
  const unreached = indicators.filter((indicator) => indicator.disagreements).length;

  const indicatorList = indicators.filter(
    (indicator) =>
      `${indicator.code} ${indicator.name}`.toLowerCase().includes(query.toLowerCase()) &&
      (statusFilter === IndicatorListFilter.All ||
        (statusFilter === IndicatorListFilter.Reached && indicator.approved) ||
        (statusFilter === IndicatorListFilter.Unreached && indicator.disagreements))
  );

  return (
    <Card title={'Статус'}>
      <Tabs<IndicatorListFilter>
        innerScroll
        className={s.IndicatorListCard__tabs}
        value={statusFilter}
        onChange={(statusFilter) => setStatusFilter(statusFilter)}
        options={[
          { id: IndicatorListFilter.All, label: `Все (${indicators.length})` },
          { id: IndicatorListFilter.Reached, label: `Утверждено (${reached})`, disabled: reached === 0 },
          { id: IndicatorListFilter.Unreached, label: `Разногласия (${unreached})`, disabled: unreached === 0 }
        ]}
      />

      <PageSearch
        className={s.IndicatorListCard__search}
        query={query}
        setQuery={setQuery}
        count={indicatorList.length}
      />

      <div className={s.IndicatorListCard__list}>
        {indicatorList.map((indicator, i) => (
          <React.Fragment key={indicator.id}>
            <Indicator
              to={`/indicators/${indicator.id}`}
              code={indicator.code}
              status={getStatus(indicator.approved)}
              statusLabel={indicator.approved ? 'Утверждено' : 'Разногласия'}>
              {indicator.name}
            </Indicator>

            {i !== indicatorList.length - 1 && <Divider />}
          </React.Fragment>
        ))}

        <Divider />
        <SharePDF kind={SharePDFKind.Indicators} data={indicators}>
          {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
        </SharePDF>
      </div>
    </Card>
  );
}
