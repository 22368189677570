import React from 'react';
import { OperationalRatingIndicatorType } from 'store/graphql';
import { RatingTitle } from 'features/_rating/RatingTitle';
import { RatingChartBlock } from 'features/_rating/RatingChartBlock';
import { ExportInfo } from '../_components/ExportInfo';
import s from './RatingIndicator.module.scss';

export interface RatingIndicatorData {
  lastUpdate?: string | null;
  indicator: Pick<
    OperationalRatingIndicatorType,
    | 'name'
    | 'currentValue'
    | 'dynamic'
    | 'sourceName'
    | 'responsibleName'
    | 'responsibleEmail'
    | 'updatePeriodicity'
    | 'maxValue'
    | 'chart'
  >;
}

function IndicatorBlock({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className={s.IndicatorBlock}>
      <div className={s.IndicatorBlock__title}>{title}</div>
      {children}
    </div>
  );
}

export function RatingIndicator({ lastUpdate, indicator: i }: RatingIndicatorData) {
  return (
    <React.Fragment>
      <RatingTitle title={i.name} value={i.currentValue || 0} lastUpdate={lastUpdate} dynamics={i.dynamic} />
      <ExportInfo className={s.RatingIndicator__exportInfo} />

      {!!i.sourceName && (
        <IndicatorBlock title={'Департамент'}>
          <div className={s.RatingIndicator__text}>{i.sourceName}</div>
        </IndicatorBlock>
      )}

      {!!i.responsibleName && (
        <IndicatorBlock title={'Ответственный'}>
          <div className={s.RatingIndicator__text}>{i.responsibleName}</div>
          {i.responsibleEmail && <div className={s.RatingIndicator__link}>{i.responsibleEmail}</div>}
        </IndicatorBlock>
      )}

      {!!i.updatePeriodicity && (
        <IndicatorBlock title={'Периодичность обновления'}>
          <div className={s.RatingIndicator__text}>{i.updatePeriodicity}</div>
        </IndicatorBlock>
      )}

      {!!i.chart.length && (
        <div className={s.RatingIndicator__chart}>
          <RatingChartBlock maxValue={i.maxValue} chart={i.chart} />
        </div>
      )}
    </React.Fragment>
  );
}
