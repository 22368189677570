export function saveAuthTarget(target) {
    // устанавливает целевую страницу для возвращения после успешной авторизации
    sessionStorage.setItem('target', target || '/');
}
export function getAuthTarget() {
    const target = sessionStorage.getItem('target');
    if (!target || target.includes('/auth'))
        return '/';
    return target;
}
