import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { RequestUtils } from 'utils/RequestUtils';
import { DepartmentRequestType } from 'store/graphql';
import { Divider } from 'components/Divider';
import { ExportInfo } from '../_components/ExportInfo';
import { Label } from '../_components/Label';
import s from './Request.module.scss';

export type RequestData = Pick<
  DepartmentRequestType,
  | 'statusCode'
  | 'statusDisplayContent'
  | 'requestTypeName'
  | 'stateUpdatedAt'
  | 'createdDate'
  | 'requestName'
  | 'groundsNames'
  | 'requestNumber'
>;

export function Request({ request }: { request: RequestData }) {
  const [label, labelType] = RequestUtils.getRequestStatusLabel(request.statusCode, request.statusDisplayContent);
  return (
    <React.Fragment>
      <h1 className={s.Request__heading}>{request.requestTypeName}</h1>
      <ExportInfo />

      <Divider className={s.Request__divider} />

      <div className={s.Request}>
        <header className={s.Request__header}>
          <Label type={labelType}>{label}</Label>
          <div className={s.Request__date}>
            {formatDate(request.stateUpdatedAt || request.createdDate, DATE_SHORT_YEAR)}
          </div>
        </header>

        <p className={s.Request__name}>{request.requestName}</p>

        {!!request.groundsNames.length && (
          <article className={s.Request__content}>
            <div className={s.Request__label}>Основание запроса</div>
            {request.groundsNames.map((nameLine) => (
              <p key={nameLine} className={s.Request__value}>
                {nameLine}
              </p>
            ))}
          </article>
        )}

        <article className={s.Request__content}>
          <div className={s.Request__label}>Номер</div>
          <p className={s.Request__value}>{request.requestNumber}</p>
        </article>

        <article className={s.Request__content}>
          <div className={s.Request__label}>Дата</div>
          <p className={s.Request__value}>{formatDate(request.createdDate, DATE_SHORT_YEAR)}</p>
        </article>
      </div>
    </React.Fragment>
  );
}
