import React from 'react';
import { OperationalRatingIndicatorType, OperationalRatingType } from 'store/graphql';
import { RatingTitle } from 'features/_rating/RatingTitle';
import { GradientRatioBar } from 'features/_rating/GradientRatioBar';
import { useIndicatorValue } from 'features/_rating/useIndicatorValue';
import { Indicator } from 'features/_rating/Indicator';
import { RatingDynamicsMap } from 'features/_rating/RatingDynamicsMap';
import { Divider } from 'components/Divider';
import { ExportInfo } from '../_components/ExportInfo';
import s from './Rating.module.scss';

type RatingIndicatorData = Pick<OperationalRatingIndicatorType, 'id' | 'name' | 'currentValue' | 'dynamic'>;

export type RatingData = Pick<OperationalRatingType, 'pointsAchieved' | 'lastUpdate' | 'relativeBarDynamicValue'> & {
  operationalRatingIndicators: RatingIndicatorData[];
};

function RatingListItem({ currentValue, name, dynamic }: RatingIndicatorData) {
  const indicatorValue = useIndicatorValue(currentValue || 0);
  return (
    <div className={s.RatingListItem}>
      <div className={s.RatingListItem__name}>{name}</div>

      <Indicator
        position={'left'}
        content={<div className={s.RatingListItem__indicatorValue}>{indicatorValue}</div>}
        showChangeIcon
        dynamics={RatingDynamicsMap[dynamic]}
      />
    </div>
  );
}

export function Rating({ rating }: { rating: RatingData }) {
  const { pointsAchieved, lastUpdate, relativeBarDynamicValue, operationalRatingIndicators } = rating;

  return (
    <React.Fragment>
      <RatingTitle title={'Оперативный рейтинг'} value={pointsAchieved || 0} lastUpdate={lastUpdate} />
      <ExportInfo className={s.Rating__exportInfo} />

      <div className={s.Rating__group}>Группа эффективности</div>
      <GradientRatioBar relativeValue={relativeBarDynamicValue || 0} scaleLinesCount={2} />
      <div className={s.Rating__groupTypes}>
        <div className={s.Rating__groupType}>Низкая</div>
        <div className={s.Rating__groupType}>Средняя</div>
        <div className={s.Rating__groupType}>Высокая</div>
      </div>

      <div className={s.Rating__list}>
        {operationalRatingIndicators.map((indicator, index) => (
          <React.Fragment key={`ri-${indicator.id}`}>
            <RatingListItem {...indicator} />
            {index !== operationalRatingIndicators.length - 1 && <Divider className={s.Rating__divider} />}
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
}
