import React, { MouseEvent, useMemo } from 'react';
import { differenceInCalendarDays, startOfDay } from 'date-fns';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash-es';
import { formatDate } from '@digital-gov/ui-utils';
import { DocumentType } from 'store/graphql';
import { Button, ButtonVariant } from 'components/Button';
import { Placeholder, PlaceholderVariant } from 'components/Placeholder';
import { WidgetCard } from '../../../features/Widgets/_components/WidgetCard';
import s from './ExpertReviewCard.module.scss';

enum DocStatus {
  Past = 'past',
  Today = 'today',
  Future = 'future'
}

const today = startOfDay(new Date());
const todayFormatted = formatDate(today, 'dd.MM.yyyy');

function getDocStatus(date?: string | null) {
  if (!date) return null;
  const d = new Date(date);
  const daysDiff = differenceInCalendarDays(d, today);
  const differentYears = d.getFullYear() !== today.getFullYear();

  if (!daysDiff) {
    return {
      status: DocStatus.Today,
      label: 'Сегодня'
    };
  }
  if (daysDiff < 0) {
    return {
      status: DocStatus.Past,
      label: daysDiff === -1 ? 'Вчера' : formatDate(d, differentYears ? 'd MMMM yyyy' : 'd MMMM')
    };
  }
  return {
    status: DocStatus.Future,
    label: daysDiff === 1 ? 'Завтра' : formatDate(d, differentYears ? 'd MMMM yyyy' : 'd MMMM')
  };
}

interface DocProps
  extends Omit<DocumentType, '_id' | 'evaluationObjects' | 'attachments' | 'outgoingName' | 'favorite'> {}

const Doc = ({ documentName, statusDate }: DocProps) => {
  const status = useMemo(() => {
    return getDocStatus(statusDate);
  }, [statusDate]);

  return (
    <div className={s.Doc}>
      <div className={s.Doc__name}>{documentName}</div>
      {status && (
        <div className={s.Doc__info}>
          <div className={clsx(s.Doc__status, s[`Doc__status_${status.status}`])} />
          <div className={s.Doc__deadlineDate}>{status.label}</div>
        </div>
      )}
    </div>
  );
};

export interface ExpertReviewCardProps {
  documents: DocProps[];
}

export function ExpertReviewCard({ documents }: ExpertReviewCardProps) {
  const navigate = useNavigate();

  const handleNavigate = (e: MouseEvent, id: string) => {
    e.preventDefault();
    navigate(`/documents/${id}`);
  };

  const hasData = documents.length > 0;

  return (
    <WidgetCard to={'/documents'}>
      <h3 className={s.ExpertReviewCard__heading}>Экспертная оценка</h3>

      <div className={s.ExpertReviewCard__content}>
        {hasData ? (
          <div className={s.ExpertReviewCard__scroll}>
            <div className={s.ExpertReviewCard__list}>
              {orderBy(documents, ['deadlineExpertDate'], ['asc']).map((d) => (
                <div
                  key={d.documentId}
                  className={s.ExpertReviewCard__item}
                  onClick={(e) => handleNavigate(e, d.documentId)}>
                  <Doc {...d} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Placeholder className={s.ExpertReviewCard__placeholder} variant={PlaceholderVariant.Documents}>
            На {todayFormatted} нет документов <br />
            на экспертизе в Минцифры России
          </Placeholder>
        )}
      </div>

      <Button Component={'span'} className={s.ExpertReviewCard__more} variant={ButtonVariant.Link}>
        Подробнее
      </Button>
    </WidgetCard>
  );
}
