import React from 'react';
import { useUpdates } from 'store/updates';
import { UpdateApp } from 'components/Icon';
import { useInstallPromptButton } from 'hooks/useInstallPromptButton';
import { LinkCardContent, ProfileCard } from '../ProfileCard/ProfileCard';

export const AppCard = () => {
  const { promptButtonVisible, promptButtonDisabled, prompt } = useInstallPromptButton();
  const { updatesAvailable } = useUpdates();

  if (updatesAvailable) {
    return (
      <ProfileCard onClick={() => window.location.reload()}>
        <LinkCardContent icon={UpdateApp}>Обновить приложение</LinkCardContent>
      </ProfileCard>
    );
  } else if (promptButtonVisible) {
    return (
      <ProfileCard disabled={promptButtonDisabled} onClick={prompt}>
        <LinkCardContent icon={UpdateApp}>Установить приложение (PWA)</LinkCardContent>
      </ProfileCard>
    );
  } else {
    return null;
  }
};
