import React from 'react';
import { Helmet } from 'react-helmet-async';
// import { UpdatesAvailable } from 'features/UpdatesAvailable';
import { AppRoutes } from './routes';

const appTitle = 'ЛК РЦТ';
export default function App() {
  return (
    <React.Fragment>
      <Helmet defaultTitle={appTitle} titleTemplate={`%s — ${appTitle}`} />
      <AppRoutes />
      {/* <UpdatesAvailable /> */}
    </React.Fragment>
  );
}
