import React, { MouseEventHandler, PropsWithChildren, useEffect, useState } from 'react';
import clsx from 'clsx';
import { DropdownArrow } from '../DropdownArrow';
import s from './Collapse.module.scss';

export interface CollapseClasses {
  root?: string;
  head?: string;
  content?: string;
}

export interface CollapseProps extends PropsWithChildren {
  className?: string;
  classes?: CollapseClasses;
  title?: React.ReactNode;
  isOpen?: boolean;
  onTriggerClick?: MouseEventHandler;
}

export const Collapse = ({ className, classes, title, isOpen, onTriggerClick, children }: CollapseProps) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleToggle: MouseEventHandler = (e) => {
    onTriggerClick?.(e);
    if (!e.isDefaultPrevented()) {
      setOpen((prevState) => !prevState);
    }
  };

  return (
    <div className={clsx(s.Collapse, className, classes?.root)}>
      <div className={clsx(s.Collapse__head, classes?.head)} onClick={handleToggle}>
        <div className={s.Collapse__title}>{title}</div>
        <DropdownArrow isOpen={open} />
      </div>

      {open && <div className={clsx(s.Collapse__content, classes?.content)}>{children}</div>}
    </div>
  );
};
