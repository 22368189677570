import React, { useState } from 'react';
import { joinNonEmpty, useSessionStorage } from '@digital-gov/ui-utils';
import { BottomSheet, Filter, FilterButton, FilterRadio } from 'components/BottomSheet';
import { Icon, ShareArrow } from 'components/Icon';
import { LibrarySort } from '../library.types';
import { useShareTextData } from '../../../hooks/useShareTextData';
import { sortOptions } from './library-filters.constants';
import s from './LibraryFilters.module.scss';

export interface LibraryFiltersProps {
  pathArray: string[];
}

export const useLibraryFilters = ({ pathArray }: LibraryFiltersProps) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [sortSS, setSortSS] = useSessionStorage('librarySort', LibrarySort.Name, { raw: true });
  const handleSharUrl = useShareTextData();

  const filter = {
    sort: sortSS || LibrarySort.Name
  };

  const handleSortChange = (value: LibrarySort) => {
    setSortSS(value);
    setFiltersOpen(false);
  };

  const handleShare = async () => {
    const title = joinNonEmpty(['Библиотека', pathArray.slice(pathArray.length - 1)], ' - ');
    const shared = await handleSharUrl({ title, url: window.location.href });
    if (shared) {
      setFiltersOpen(false);
    }
  };

  const component = (
    <BottomSheet
      className={s.LibraryFilters__bottomSheet}
      onClose={() => setFiltersOpen(false)}
      showCloseButton={false}>
      <div className={s.LibraryFilters}>
        <Filter className={s.LibraryFilters__filter} title={'Сортировать по'}>
          <FilterRadio value={filter.sort} options={sortOptions} onChange={handleSortChange} />
        </Filter>
        <Filter className={s.LibraryFilters__filter}>
          <FilterButton onClick={handleShare}>
            <Icon icon={ShareArrow} />
            Поделиться ссылкой
          </FilterButton>
        </Filter>
      </div>
    </BottomSheet>
  );

  return [component, filter, filtersOpen, setFiltersOpen] as const;
};
