import React, { useLayoutEffect } from 'react';
import { isIOS } from '@digital-gov/ui-utils';
import { NavBarItem } from './NavBarItem';
import { ReactComponent as HomeIcon } from './icons/home.svg';
import { ReactComponent as BellIcon } from './icons/bell.svg';
import { ReactComponent as ServicesIcon } from './icons/services.svg';
import { ReactComponent as LibraryIcon } from './icons/library.svg';
import { ReactComponent as ProfileIcon } from './icons/profile.svg';
import s from './NavBar.module.scss';

export function NavBar() {
  useLayoutEffect(() => {
    if (isIOS) document.documentElement.style.setProperty('--nav-bar-height', '80px');
  }, []);

  return (
    <nav className={s.NavBar}>
      <div className={s.NavBar__items}>
        <NavBarItem Icon={HomeIcon} to={'/'}>
          Главная
        </NavBarItem>
        <NavBarItem Icon={BellIcon} disabled>
          События
        </NavBarItem>
        <NavBarItem Icon={ServicesIcon} to={'/services'} pattern={'/services/*'}>
          Сервисы
        </NavBarItem>
        <NavBarItem Icon={LibraryIcon} to={'/library'} pattern={'/library/*'}>
          Библиотека
        </NavBarItem>
        <NavBarItem Icon={ProfileIcon} to={'/profile'}>
          Профиль
        </NavBarItem>
      </div>
    </nav>
  );
}
